import { css } from 'lit';

const AdeleGlowCardsFaqStyles = [
  css`
    :host {
      --bg: hsl(246 44% 7%);
      --border: hsl(280 10% 50% / 1);
      --card: hsl(237 36% 10%);
      --color: hsl(240 18% 80%);
      --border-width: 2px;
      --border-radius: 16px;
      --gradient: conic-gradient(
        from 180deg at 50% 70%,
        hsla(0, 0%, 98%, 1) 0deg,
        #f8029c 90deg,
        #6c00ff 180deg,
        #0dbeff 270deg,
        #00dfb1 360deg,
        hsla(0, 0%, 98%, 1) 1turn
      );
      --blurValue: 20;
      --start: 0;
    }

    *,
    *:after,
    *:before {
      box-sizing: border-box;
    }

    .glow-body {
      display: grid;
      place-items: center;
      font-family: 'Geist Sans', 'SF Pro Text', 'SF Pro Icons', 'AOS Icons',
        'Helvetica Neue', Helvetica, Arial, sans-serif, system-ui;
      font-weight: 70;
      color: hsl(240 18% 80%);
      width: 100%;
      height: 100%;
    }

    .glow-container {
      --spread: 60;
      display: flex;
      flex-wrap: wrap;
      flex-direction: var(--direction);
      gap: var(--gap);
      margin: 0 auto;
      justify-content: center;
      place-items: center;
      position: relative;
      padding: 2rem;
      touch-action: none;
      transition: all 0.3s ease;
    }

    article {
      --active: 0.15;
      --start: 0;
      height: 100%;
      width: 280px;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative;
      padding: 16px 19px;
      border-radius: 16px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 24px 30px 0px;
      backdrop-filter: blur(18px);
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      cursor: pointer;
    }

    article:is(:hover, :focus-visible) {
      z-index: 2;
    }

    .glows {
      pointer-events: none;
      position: absolute;
      inset: 0;
      filter: blur(20px);
    }

    .glows::after,
    .glows::before {
      --alpha: 0;
      --spread: 60;
      content: '';
      background: var(--gradient);
      background-attachment: fixed;
      position: absolute;
      inset: -5px;
      border: 10px solid transparent;
      border-radius: 16px;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
          #000 0deg,
          #fff,
          transparent calc(var(--spread) * 1deg)
        );
      mask-composite: intersect;
      mask-clip: padding-box, border-box;
      opacity: var(--active);
      transition: opacity 1s;
    }

    article::before {
      position: absolute;
      inset: 0;
      border: 2px solid transparent;
      content: '';
      pointer-events: none;
      background: hsl(280 10% 50% / 1);
      background-attachment: fixed;
      border-radius: 16px;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from
            calc(
              ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) *
                1deg
            ),
          hsl(0 0% 100% / 0.15) 0deg,
          white,
          hsl(0 0% 100% / 0.15) calc(var(--spread) * 2.5deg)
        );
      mask-clip: padding-box, border-box;
      mask-composite: intersect;
      opacity: var(--active);
      transition: opacity 1s;
    }

    article::after {
      --bg-size: 100%;
      content: '';
      pointer-events: none;
      position: absolute;
      background: var(--gradient);
      background-attachment: fixed;
      border-radius: 16px;
      opacity: var(--active, 0);
      transition: opacity 1s;
      --alpha: 0;
      inset: 0;
      border: 2px solid transparent;
      mask: linear-gradient(#0000, #0000),
        conic-gradient(
          from
            calc(
              ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) *
                1deg
            ),
          #0000 0deg,
          #fff,
          #0000 calc(var(--spread) * 0.5deg)
        );
      filter: brightness(1.5);
      mask-clip: padding-box, border-box;
      mask-composite: intersect;
    }

    .badge {
      border: 2px solid hsl(280 10% 50% / 1);
      align-self: start;
      border-radius: 100px;
      padding: 0.5rem 0.7rem;
      font-size: 0.675rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      font-weight: 50;
      width: 130px;
    }

    a {
      color: hsl(240 18% 80%);
      text-decoration: none;
      opacity: 0.5;
      display: inline-block;
      align-self: start;
      transition: opacity 0.2s;
    }

    a:is(:hover, :focus-visible) {
      opacity: 1;
    }

    article h2 {
      margin: 0;
      padding: 1rem 0;
      font-weight: 100;
      font-size: 1.5rem;
    }

    article h2,
    article p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .header {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .header svg {
      --count: 4;
      width: 106px;
    }

    .header svg:nth-of-type(2),
    .header svg:nth-of-type(3),
    .header svg:nth-of-type(4) {
      position: absolute;
      z-index: calc(var(--count) - var(--index));
      translate: calc(var(--index) * 30%) 0;
      opacity: calc(var(--count) / (2 * (var(--index) * 10)));
    }

    .header svg:nth-of-type(2) {
      --index: 1;
    }
    .header svg:nth-of-type(3) {
      --index: 2;
    }
    .header svg:nth-of-type(4) {
      --index: 3;
    }

    .badge svg {
      width: 16px;
    }

    .dg.ac {
      z-index: 99999 !important;
    }

    .details {
      display: none;
      flex-direction: column;
      overflow: hidden;
      transition: max-height 0.3s ease, display 0.3s ease;
      gap: 0.5rem;
      margin: 0 0 0 10px;
      max-height: 285px;
      overflow: auto;
      width: 301px;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(255, 255, 255, 0.3);
      background-clip: padding-box;
      border-radius: 25px;
      height: 50px;
    }

    .threads-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .thread-item {
      cursor: pointer;
      display: flex;
      font-weight: 700;
      margin: 0 8px 0 0;
      border-radius: 12px;
      padding: 0.5rem 0.75rem;
      transition: background 0.3s, color 0.3s;
      text-transform: capitalize;
    }

    .thread-item:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    .card-main-left {
      width: 242px;
      height: 285px;
    }
  `,
];

export { AdeleGlowCardsFaqStyles };
