import { property } from 'lit/decorators';
import { PropertyValues } from 'lit';

// Web Viz Components
import {
  CSSResult,
  NNBase,
  css,
  html,
} from '@mch/nn-web-viz/dist/packages/base/Base';
import '@mch/nn-web-viz/dist/nn-tiles';
import '@mch/nn-web-viz/dist/nn-button';

// Components
import '../../../components/adele-glow-cards-faq';

import { trackPageView } from '@mch/nn-web-viz/dist/packages/analytics';

// Store
import { connect, store } from '../../../state/store';

class AdeleFrequent extends connect(store)(NNBase) {
  @property({ type: Object }) user;

  static styles: CSSResult[] = [
    css`
      .page-wrapper {
        border-radius: 24px;

        background: linear-gradient(
          237deg,
          rgba(28, 31, 40, 0.1) 5.65%,
          rgba(28, 31, 40, 0.3) 85.87%,
          rgba(28, 31, 40, 0.5) 97.63%
        );
        box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(18px);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 10px;
      }
      .title {
        color: #fff;
        text-align: start;
        justify-content: start;
        width: 100%;
        margin-left: 40px;
      }
      .faq-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
      }

      .card {
        border-radius: 8px;
        background: rgba(15, 17, 30, 0.5);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        padding: 32px 20px 16px 16px;
        flex-direction: column;
        display: flex;
        width: calc(32% - 38px);
        height: 220px;
        gap: 30px;
        transition: all 0.8s;
        overflow: scroll;
        border: 1px solid transparent;
      }

      .card:hover {
        border: 1px solid var(--Gradient-Brand, #f8029c);
        background: linear-gradient(
          90deg,
          rgba(248, 2, 156, 0.06) 0%,
          rgba(108, 0, 255, 0.06) 100%
        );
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .card h3 {
        margin: 0;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
      }

      .card p {
        font-size: 16px;
        color: #fff;
        opacity: 0.8;
      }

      .continue-button a {
        background-color: #5e67ae;
        color: #ffffff;
        text-align: center;
        width: auto;
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        position: relative;
        top: 16px;
        right: 16px;
      }

      adele-glow-cards-faq {
        width: 100%;
      }
    `,
  ];

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    this.setupButtonClickListener();
    trackPageView({ page: 'FREQUENT_TOPIC_PAGE' });
  }

  setupButtonClickListener() {
    const button = this.shadowRoot?.getElementById('search1');
    const inputField = this.shadowRoot?.getElementById('search');

    button?.addEventListener('click', () => {
      const askTitleElement = button?.querySelector('.ask-title');
      const askTitle = askTitleElement ? askTitleElement.textContent : '';
      inputField?.setAttribute('value', askTitle || '');
    });
  }

  protected render(): unknown {
    return html`
      <div class="page-wrapper">
        <div class="title">
          <h1>Frequent Topics</h1>
        </div>
        <adele-glow-cards-faq></adele-glow-cards-faq>
      </div>
    `;
  }
}

export { AdeleFrequent };
