import { LitElement, PropertyValues, html, nothing } from 'lit';
import { property } from 'lit/decorators';
import '@mch/nn-web-viz/dist/nn-spinner';
import { navigate } from '@mch/nn-web-viz/dist/packages/router';
import {
  sendEvent,
  trackPageView,
} from '@mch/nn-web-viz/dist/packages/analytics';
import { connect, store } from '../../../../state/store';
import { clearSelectedThread } from '../../../../state/slices/thread';
import { routePrefix } from '../../../../utils';
import { setUserQuery } from '../../../../state/slices/userQuery';
import { AdeleGlowCardsFaqStyles } from './AdeleGlowCardsFaqStyles';

interface Thread {
  title: string;
  description: string;
}

const createThread = thread => {
  store.dispatch(clearSelectedThread());
  store.dispatch(setUserQuery(thread.name));

  navigate(`${routePrefix}/home`);
};

class AdeleGlowCardsFaq extends connect(store)(LitElement) {
  @property({ type: Array }) cards: {
    id: number;
    title: string;
    description: string;
    imgSrc?: string;
    threads?: Array<any>;
  }[] = [];

  @property({ type: Number }) proximity = 100;

  @property({ type: Number }) gap = 32;

  @property({ type: Number }) blurValue = 20;

  @property({ type: Number }) spread = 60;

  @property({ type: Boolean }) vertical = false;

  @property({ type: Number }) opacity = 0;

  @property({ type: String }) direction = 'row';

  @property({ type: Number }) expandedCardId: number | null = null;

  @property({ type: Number }) idToShowThreads: number | null = null;

  @property({ type: Array }) threads: Thread[][] = [];

  @property({ type: Array }) _threadsToShow: Array<any> = [];

  private animationFrameId: number | null = null;

  static styles = [...AdeleGlowCardsFaqStyles];

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    trackPageView({ page: 'FREQUENT_TOPIC_PAGE' });
  }

  constructor() {
    super();
    this.cards = [
      {
        id: 1,
        title: 'Treatment Insights',
        description:
          'Explore in-depth information about various treatment options, their effectiveness, and patient outcomes',
        imgSrc:
          'https://assets.codepen.io/7773162/purple-bg.jpeg?format=auto&quality=20',
        threads: [
          {
            name: 'How does HER3-DXd target cancer cells?',
            description:
              'Discussion on the mechanism of action of HER3-DXd in targeting cancer cells.',
          },
          {
            name: 'What are the clinical outcomes of HER3-DXd treatments?',
            description:
              'Analysis of the clinical outcomes observed in patients treated with HER3-DXd.',
          },
          {
            name: 'What are the side effects associated with HER3-DXd?',
            description:
              'Insights into the side effects and management strategies for patients on HER3-DXd.',
          },
        ],
      },
      {
        id: 2,
        title: 'Study Timelines',
        description:
          'Get a comprehensive overview of the study schedules, key milestones, and important deadlines',
        imgSrc:
          'https://assets.codepen.io/7773162/orange-bg+2+Small.png?format=auto&quality=80',
        threads: [
          {
            name: 'What are the phases of HER3-DXd clinical trials?',
            description:
              'Detailed explanation of the different phases in HER3-DXd clinical trials and their timelines.',
          },
          {
            name: 'What is the timeline for HER3-DXd regulatory approval?',
            description:
              'Overview of the steps and timelines involved in gaining regulatory approval for HER3-DXd.',
          },
          {
            name: 'How are HER3-DXd study milestones tracked?',
            description:
              'Best practices for managing timelines and milestones in HER3-DXd clinical research projects.',
          },
        ],
      },
      {
        id: 3,
        title: 'Study Challenges',
        description:
          'Learn about the common obstacles faced during studies and strategies to overcome them',
        imgSrc:
          'https://assets.codepen.io/7773162/yellow-bg.jpeg?format=auto&quality=50',
        threads: [
          {
            name: 'What are the recruitment challenges in HER3-DXd studies?',
            description:
              'Effective methods to recruit and retain participants in HER3-DXd clinical studies.',
          },
          {
            name: 'How is data integrity maintained in HER3-DXd research?',
            description:
              'Challenges in data collection and ensuring data integrity in HER3-DXd clinical trials.',
          },
          {
            name: 'What ethical considerations are important in HER3-DXd studies?',
            description:
              'Navigating the ethical issues and ensuring compliance in HER3-DXd clinical research.',
          },
        ],
      },
      {
        id: 4,
        title: 'Outcomes Comparison',
        description:
          'Compare the results and impacts of different studies to identify the most successful approaches',
        imgSrc:
          'https://assets.codepen.io/7773162/red-bg.jpeg?format=auto&quality=50',
        threads: [
          {
            name: 'Can you compare HER3-DXd results for breast cancer and NSCLC?',
            description:
              'Comparison of HER3-DXd key results for breast cancer and Non-Small Cell Lung Cancer (NSCLC).',
          },
          {
            name: 'How do HER3-DXd survival rates compare to other treatments?',
            description:
              'Comparison of survival rates between HER3-DXd and other treatment modalities.',
          },
          {
            name: 'What is the impact of HER3-DXd on patients’ quality of life?',
            description:
              'Evaluating the quality of life outcomes for patients treated with HER3-DXd.',
          },
        ],
      },
      {
        id: 5,
        title: 'Subtype Differences',
        description:
          'Understand the distinctions between different subtypes and their implications on research and treatment',
        imgSrc:
          'https://assets.codepen.io/7773162/glass-bg.jpeg?format=auto&quality=20',
        threads: [
          {
            name: 'How does HER3-DXd perform in different breast cancer subtypes?',
            description:
              'Detailed look at the effectiveness of HER3-DXd in various breast cancer subtypes.',
          },
          {
            name: 'What genetic markers influence HER3-DXd efficacy?',
            description:
              'Exploration of genetic markers that impact the efficacy of HER3-DXd treatment.',
          },
          {
            name: 'Do different cancer subtypes respond differently to HER3-DXd?',
            description:
              'Analysis of response variations to HER3-DXd across different cancer subtypes.',
          },
        ],
      },
    ];

    this.threads = this.cards.map(card => card.threads || []);

    this.restyle();
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('idToShowThreads')) {
      if (this.idToShowThreads != null) {
        this._threadsToShow =
          this.cards.find(card => card.id === this.idToShowThreads)?.threads ||
          [];
      }
    }
  }

  toggleCardExpansion(cardId) {
    this.idToShowThreads = this.idToShowThreads === cardId ? null : cardId;
    this.cards.forEach(card => {
      const cardElement = this.shadowRoot?.querySelector(
        `article[data-id="${card.id}"]`
      ) as HTMLElement;
      if (cardElement) {
        cardElement.style.width =
          this.idToShowThreads === card.id ? '593px' : '280px';
        cardElement.style.flexDirection =
          this.idToShowThreads === card.id ? 'row' : 'column';
        const details = cardElement.querySelector('.details') as HTMLElement;
        details.style.display =
          this.idToShowThreads === card.id ? 'inline-flex' : 'none';

        const description = cardElement.querySelector(
          '#description'
        ) as HTMLElement;
        const image = cardElement.querySelector('img') as HTMLElement;
        if (description && image && card.description.length > 28) {
          if (this.idToShowThreads === card.id) {
            description.style.height = '99px';
            description.style.overflowY = 'scroll';
            image.style.height = '80px';
          } else {
            description.style.height = '20px';
            description.style.overflowY = 'scroll';
            image.style.height = '160px';
            image.style.width = '100%';
            image.style.objectFit = 'cover';
          }
        }
      }
      const badge = this.shadowRoot?.querySelector(
        `article[data-id="${card.id}"] .view-collection`
      );
      if (badge) {
        badge.innerHTML =
          this.idToShowThreads === card.id ? 'Hide Topic' : 'View Topic';
      }
    });
  }

  restyle() {
    const container = this.shadowRoot?.querySelector(
      '.glow-container'
    ) as HTMLElement;
    if (container) {
      container.style.setProperty('--gap', `${this.gap}px`);
      container.style.setProperty('--blur-value', `${this.blurValue}px`);
      container.style.setProperty('--spread', `${this.spread}`);
      container.style.setProperty(
        '--direction',
        this.vertical ? 'column' : 'row'
      );
    }
  }

  updateCardStyles(event) {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.animationFrameId = requestAnimationFrame(() => {
      this.cards.forEach(card => {
        const cardElement = this.shadowRoot?.querySelector(
          `article[data-id="${card.id}"]`
        );
        if (cardElement instanceof HTMLElement) {
          const bounds = cardElement.getBoundingClientRect();
          const inProximity =
            event.clientX > bounds.left - this.proximity &&
            event.clientX < bounds.right + this.proximity &&
            event.clientY > bounds.top - this.proximity &&
            event.clientY < bounds.bottom + this.proximity;

          const currentActive =
            cardElement.style.getPropertyValue('--active') === '1';
          if (inProximity && !currentActive) {
            cardElement.style.setProperty('--active', '1');
          } else if (!inProximity && currentActive) {
            cardElement.style.setProperty('--active', '0');
          }

          if (inProximity) {
            const centerX = bounds.left + bounds.width / 2;
            const centerY = bounds.top + bounds.height / 2;
            let angle =
              Math.atan2(event.clientY - centerY, event.clientX - centerX) *
              (180 / Math.PI);
            angle = angle < 0 ? angle + 360 : angle;
            angle = Math.round(angle);
            cardElement.style.setProperty('--start', `${angle + 90}`);
          }
        }
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();
    // eslint-disable-next-line wc/require-listener-teardown
    this.addEventListener('pointermove', this.updateCardStyles.bind(this));
    this.restyle();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // eslint-disable-next-line wc/require-listener-teardown
    this.removeEventListener('pointermove', this.updateCardStyles.bind(this));
  }

  cardClicked(cardId) {
    this.dispatchEvent(
      new CustomEvent('card-selected', {
        detail: { cardId },
        bubbles: true,
        composed: true,
      })
    );
  }

  _handleThreadClick(threadId) {
    const event = new CustomEvent('thread-clicked', {
      detail: { threadId },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  _renderThreadsToShow(id) {
    return this.idToShowThreads === id
      ? this._threadsToShow.map(
          thread => html`<div
            class="thread-item"
            @click=${() => createThread(thread)}
            @keyup=${() => createThread(thread)}
          >
            <div>${thread.name}</div>
          </div>`
        )
      : nothing;
  }

  render() {
    return html`
      <div class="glow-body">
        <div
          class="glow-container"
          style="--gap: ${this.gap}px; --blur-value: ${this
            .blurValue}px; --spread: ${this.spread}; --direction: ${this
            .vertical
            ? 'column'
            : 'row'};"
        >
          ${this.cards.map(
            card => html`
              <article
                data-id="${card.id}"
                nn-analytics-id=${card.id}
                nn-analytics-description=${card.title}
                @click=${e => {
                  sendEvent(e);
                  this.toggleCardExpansion(card.id);
                }}
                @keyup=${e => {
                  sendEvent(e);
                  this.toggleCardExpansion(card.id);
                }}
                style="--active: ${this.opacity};"
              >
                <div class="glows"></div>
                <div class="card-main-left">
                  <span class="header">
                    <img
                      style="height:160px;width:100%;object-fit: cover;"
                      src="${card.imgSrc || ''}"
                      alt=""
                    />
                  </span>
                  <h2>${card.title}</h2>
                  <p id="description" style="height:20px;margin:0 0 8px 0;">
                    ${card.description}
                  </p>
                  <span class="badge">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="view-collection">View Topic</span>
                  </span>
                </div>
                <div class="details">${this._renderThreadsToShow(card.id)}</div>
              </article>
            `
          )}
        </div>
      </div>
    `;
  }
}

export { AdeleGlowCardsFaq };
